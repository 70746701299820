import React, { useState, useEffect } from "react";
import "../../styles/privacy/privacy.css";
import PrivacyHeader from "../../components/privacy_page/privacy_header"
import TermsContent from "../../components/terms_page/terms_content"
import CommonFooter from "../../components/footer";

const TermsPage = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>  

      <PrivacyHeader />
      <TermsContent />
      <CommonFooter />

    </>
  );
};

export default TermsPage;