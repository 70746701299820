import React, { useState, useEffect } from "react";
import CareerHeader from "../../components/careers_page/career_header"
import CommonFooter from "../../components/footer";
import { ColorRing } from 'react-loader-spinner';

const CareersPage = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [loading, setLoading] = useState(true);
  const imagePaths = [
    require('../../assets/common/common_header.png'),
    require('../../assets/common/mob_common_header.png'),
    require('../../assets/common/banner.png'),
    require('../../assets/careers_page/join.png'),
    require('../../assets/support_page/support_avatar.png'),
    require('../../assets/common/ham.png'),
    require('../../assets/support_page/support_avatar.png')
  ];

  useEffect(() => {
    const imagePromises = imagePaths.map((imagePath) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imagePath;
        img.onload = () => resolve();
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setLoading(false);
      });
  }, [imagePaths]);

  return (
    <>

      {loading ? (

        <div className="loader-container">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        </div>

      ) : (
        <div>
          <CareerHeader />
          <CommonFooter />
        </div>
      )}


    </>
  );
};

export default CareersPage;