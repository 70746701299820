import React, { useState, useEffect } from "react";
import "../../styles/careers_page/careers_page.css";
import header from "../../assets/common/common_header.png";
import mobHeader from "../../assets/common/mob_common_header.png";
import banner from "../../assets/common/banner.png";
import join from "../../assets/careers_page/join.png";
import careerAvatar from "../../assets/careers_page/career_avatar.png";
import ham from "../../assets/common/ham.png";
import Drawer from '@mui/material/Drawer';
import brandBanner from "../../assets/common/brand_logo.png";
import close from "../../assets/common/close.png";
import Divider from '@mui/material/Divider';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';

const CareerHeader = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const navigate = useNavigate();

  const [age, setAge] = React.useState('Your Position');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [formData, setFormData] = useState({
    name: '',
    mail: '',
    phone: '',
    position: 'Your Position', // Initial value for the select input
    cv: null // Initially no file is selected
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFormData(prevState => ({
      ...prevState,
      cv: file
    }));
  };  

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const form = new FormData();
    form.append('name', formData.name);
    form.append('mail', formData.mail);
    form.append('phone', formData.phone);
    form.append('position', formData.position);
    form.append('cv', formData.cv);
  
    try {
      const response = await axios.post('https://prod.giggletoday.com/helpers/career/', form);
      console.log(response.data); // Assuming your API returns some data
      // Optionally reset the form after successful submission
      setFormData({
        name: '',
        mail: '',
        phone: '',
        position: 'Your Position',
        cv: null
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsLoading(false);
      // Handle error appropriately
    }
  };
  
  

  return (
    <>

          <div className="career-section">
              <img src={header} alt="" className="career-header" />
              <img src={mobHeader} alt="" className="mob-career-header" />
              <img src={banner} alt="Banner Image" className="career-header-section-banner" onClick={() => navigate(`/`)}/>
              <img src={join} alt="Banner Image" className="career-header-section-join" />
              <img src={ham} alt="" className="career-header-section-ham" onClick={handleDrawerOpen}/>

              <div className="career-header-link-row">

                  <div className="header-link-style" onClick={() => navigate(`/support`)}>
                      Support
                  </div>

                  <div className="header-link-style" onClick={()=> navigate(`/careers`)}>
                      Careers
                  </div>



                  <div className="header-link-style" onClick={() => navigate(`/ambassadors`)}>
                      Ambassadors
                  </div>

              </div>

              <Drawer
                  sx={{
                      width: '75%',
                      flexShrink: 0,
                      '& .MuiDrawer-paper': {
                          width: '75%',
                      },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={open}
              >

                  <div className="career-header-drawer-first-row">

                      <img src={brandBanner} alt="" className="header-drawer-banner" />
                      <img src={close} alt="" className="header-drawer-close" onClick={handleDrawerClose} />

                  </div>

                  <Divider sx={{ marginTop: '30%', width: '80%', marginLeft: '30px' }} />

                  <div className="header-drawer-link" onClick={() => navigate(`/support`)}>
                      Support
                  </div>

                  <div className="header-drawer-link" onClick={()=> navigate(`/careers`)}>
                      Careers
                  </div>

                  <div className="header-drawer-link" onClick={() => navigate(`/ambassadors`)}>
                      Ambassadors
                  </div>




              </Drawer>

              <div className="career-avatar-main-row">

                  <div className="career-avatar-row">
                      
                      <div className="career-avatar-left-column">
                        <div className="career-avatar-left-column-text">
                            Join us In Building Social Belongings
                        </div>
                        <img src={careerAvatar} alt="" className="career-avatar-size" />
                      </div>

                      <div className="career-avatar-column">

                          <div className="text-field-container">
                              <input type="text" className="textfield" placeholder="Full Name*" name="name" value={formData.name} onChange={handleInputChange}/>
                          </div>


                          <div className="text-field-container">
                              <input type="text" className="textfield" placeholder="Email Address*" name="mail" value={formData.mail} onChange={handleInputChange}/>
                          </div>

                          <div className="text-field-container">
                              <input type="text" className="textfield" placeholder="Phone Number*" name="phone" value={formData.phone} onChange={handleInputChange}/>
                          </div>

                          <div className="text-field-container-row ">

                              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                              <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  className="text-field-container-2"
                                  name="position"
                                  value={formData.position}
                                  onChange={handleInputChange}
                                  placeholder="Your Position"
                                  sx={{border:"none", outline:"none"}}
                              >
                                  <MenuItem value={'Your Position'} disabled sx={{color:'#999999'}}>Your Position</MenuItem>
                                  <MenuItem value={'Flutter Developer - Front-End'}>Flutter Developer - Front-End</MenuItem>
                                  <MenuItem value={'React JS Developer - Front-End'}>React JS Developer - Front-End</MenuItem>
                                  <MenuItem value={'UI/UX Designer'}>UI/UX Designer</MenuItem>
                                  <MenuItem value={'Data Analyst'}>Data Analyst</MenuItem>
                                  <MenuItem value={'Marketing'}>Marketing</MenuItem>
                                  <MenuItem value={'Content Writer'}>Content Writer</MenuItem>
                              </Select>
                              
                          <div className="text-field-container-1">
                              <label for="cv" class="btn" onChange={handleFileChange} className="file-input-text">{selectedFile ? selectedFile.name : "Attach Resume"}</label>
                              <input type="file" id="cv" className="file-input" onChange={handleFileChange} placeholder="Attach Resume" sx={{width:'10px'}} />
                          </div>

                          </div>

                          <div className="apply-button-row">
                              {isLoading ? (
                                  <div className="apply-button">
                                      {/* Loader component or spinner */}
                                      <div className="career-loader"></div>
                                  </div>
                              ) : (
                                  <div className="apply-button" onClick={handleSubmit}>
                                      Apply
                                  </div>
                              )}
                          </div>

                          




                      </div>
                  </div>

              </div>

              <Divider sx={{width: '80%', marginTop: '50px'}}/>

              
          </div>

    </>
  );
};

export default CareerHeader;