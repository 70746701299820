import React, { useState, useEffect } from "react";
import "../../styles/home_page/home_page.css";
import HeaderSection from "../../components/home_page/header_section";
import ShowCaseSection from "../../components/home_page/showcase_section";
import Feature1Section from "../../components/home_page/feature_1";
import Feature2Section from "../../components/home_page/feature_2";
import Feature3Section from "../../components/home_page/feature_3";
import FeatureCarousal from "../../components/home_page/feature_carousal";
import HomeFooter from "../../components/home_footer";
import { ColorRing } from 'react-loader-spinner';

const Home = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [loading, setLoading] = useState(true);
  const imagePaths = [



    require('../../assets/home_page/home_sec_4.png'),
    require('../../assets/home_page/mob_home_sec_4.png'),
    require('../../assets/home_page/home_4_mockup.png'),
    require('../../assets/home_page/home_1_right_globe.png'),


    require('../../assets/home_page/left_earth.png'),
    require('../../assets/home_page/home_sec_3.png'),
    require('../../assets/home_page/mob_home_sec_3.png'),
    require('../../assets/home_page/home_3_mockup.png'),



    require('../../assets/home_page/home_sec_2.png'),
    require('../../assets/home_page/mob_home_sec_2.png'),
    require('../../assets/home_page/home_2_mockup.png'),
    require('../../assets/home_page/home_2_right_globe.png'),
    require('../../assets/home_page/mob_below_earth.png'),



    require('../../assets/home_page/upper_earth.png'),
    require('../../assets/home_page/home_1_right_globe.png'),
    require('../../assets/home_page/home_1_mockup.png'),
    require('../../assets/home_page/mob_home_sec_1.png'),
    require('../../assets/home_page/home_sec_1.png'),



    require('../../assets/home_page/web_first.png'),
    require('../../assets/common/banner.png'),
    require('../../assets/home_page/web_header.png'),
    require('../../assets/home_page/mob_header.png'),
    require('../../assets/common/playstore.png')
  ];

  useEffect(() => {
    const imagePromises = imagePaths.map((imagePath) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = imagePath;
        img.onload = () => resolve();
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setLoading(false);
      });
  }, [imagePaths]);

  return (
    <>

      {loading ? (

        <div className="loader-container">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        </div>
       
      ) : (
        <div>
          <HeaderSection />
          <ShowCaseSection />
          <Feature1Section />
          <Feature2Section />
          <Feature3Section />
          {/* <FeatureCarousal /> */}
          <HomeFooter />
        </div>
      )}

    </>
  );
};

export default Home;