import React, { useState, useEffect } from "react";
import "../styles/footer/footer.css";
import footerCircle from "../assets/common/footer_circle.png";
import footerFlowers from "../assets/common/footer_flowers.png";
import apiDone from "../assets/home_page/done_api.png";
import x from "../assets/common/x.png";
import faceboook from "../assets/common/facebook.png";
import instagram from "../assets/common/instagram.png";
import linkedin from "../assets/common/linkedin.png";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import playstore from "../assets/common/playstore.png";
import appstore from "../assets/common/appstore.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomeFooter = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: smooth scrolling behavior
    });
  };

  const handleImageClick = (url) => {
    window.open(url, "_blank");
  };

  const [email, setEmail] = useState('');
  const [showWaitlist, setShowWaitlist] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    const handleJoinWaitlist = () => {
        setIsLoading(true);
        // Perform API call to join the waitlist using Axios
        axios.post('https://prod.giggletoday.com/helpers/post_waitlist/', { mail: email })
            .then(response => {
                if (response.status === 200) {
                    setShowWaitlist(false); // Hide waitlist form
                    setShowSuccess(true); // Show success message
                    setIsLoading(false);
                } else {
                    // Handle error responses
                    console.error('Error joining waitlist');
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error joining waitlist:', error);
                setIsLoading(false);
            });
    };

    const handlePlaystoreClick = (event) => {
        event.preventDefault();
        window.open('https://play.google.com/store/apps/details?id=com.schaffen.giggle', '_blank');
      };

    const handleAppstoreClick = (event) => {
        event.preventDefault();
        window.open('https://apps.apple.com/us/app/giggle-make-friends-chat/id6498945325', '_blank');
      };

  return (
    <>
    <ToastContainer />

    <div className="footer-section">

        {/* <img src={footerCircle} alt="" className="home-footer"/> */}

              <div className="footer-registration-column">

                  <div className="footer-waitlist-heading">
                      Ready to start your Journey?
                  </div>

                  <div className="footer-download-links-row">
                    <img src={playstore} alt="" className="playstore-banner-footer" onClick={handlePlaystoreClick}/>
                    <img src={appstore} alt="" className="appstore-banner-footer" onClick={handleAppstoreClick}/>
                  </div>

                  {/* {showWaitlist && (
                      <div className="footer-waitlist-container">
                          <input
                              type="text"
                              className="footer-waitlist-text-field"
                              placeholder="Your E-Mail"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                          />
                          <div className="footer-waitlist-btn" onClick={handleJoinWaitlist}>
                              {isLoading ? (
                                  <div className="home-loader"></div>
                              ) : (
                                  "Join Waitlist"
                              )}
                          </div>
                      </div>
                  )} */}


                    {/* {showSuccess && (
                        <img src={apiDone} alt="Success" className="footer-done" />
                    )} */}

              </div>

        <div className="footer-parent-row">
            <div className="footer-main-row">

                      <div className="home-copy-right-text">
                          <div className="footer-social-row">

                              <img src={instagram} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.instagram.com/giggle.today/")} />
                              <img src={faceboook} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.facebook.com/giggle.x.today/")} />
                              <img src={x} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://twitter.com/giggle_today/")} />                       
                              <img src={linkedin} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.linkedin.com/company/giggle-today/")} />

                          </div>
                          2024 Giggle LLC, All Rights Reserved.
                      </div>

                <div className="footer-links-column">

                    <div className="footer-links" onClick={() => navigate(`/support`)}>
                        Support
                    </div>
                    
                    <div className="footer-links" onClick={()=> navigate(`/careers`)}>
                        Careers
                    </div>

                </div>

                <div className="footer-links-column">

                    <div className="footer-links" onClick={()=> navigate(`/privacy`)}>
                        Privacy Policy
                    </div>
                    
                    <div className="footer-links-1" onClick={() => navigate(`/ambassadors`)}>
                        Ambassadors
                    </div>

                </div>

                <div className="footer-links-column-1">

                    <div className="footer-links" onClick={()=> navigate(`/terms`)}>
                        Terms Of Services
                    </div>
                    
                    <div className="footer-links" onClick={()=> navigate(`/guidelines`)}>
                        Community Guidelines
                    </div>

                </div>

            </div>

            <div className="mob-footer-main-row">

                      

                      <div className="footer-link-row">

                          <div className="footer-links-column">

                              <div className="footer-links" onClick={() => navigate(`/support`)}>
                                  Support
                              </div>

                              <div className="footer-links" onClick={()=> navigate(`/careers`)}>
                                  Careers
                              </div>

                              <div className="footer-links" onClick={() => navigate(`/terms`)}>
                                  Terms Of Services
                              </div>

                          </div>

                          <div className="footer-links-column">

                              <div className="footer-links" onClick={() => navigate(`/privacy`)}>
                                  Privacy Policy
                              </div>

                              <div className="footer-links-1" onClick={() => navigate(`/ambassadors`)}>
                                  Ambassadors
                              </div>

                              <div className="footer-links" onClick={() => navigate(`/guidelines`)}>
                                  Community Guidelines
                              </div>

                          </div>

                      </div>

                      <div className="home-copy-right-text">
                          <div className="footer-social-row">
                            
                              <img src={instagram} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.instagram.com/giggle.today/")} />
                              <img src={faceboook} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.facebook.com/giggle.x.today/")} />
                              <img src={x} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://twitter.com/giggle_today/")} />
                              <img src={linkedin} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.linkedin.com/company/giggle-today/")} />

                          </div>
                          2024 Giggle LLC, All Rights Reserved.
                      </div>

            </div>
        </div>

        <img src={footerFlowers} alt="" className="home-footer"/>

    </div>

    </>
  );
};

export default HomeFooter;