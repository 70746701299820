import React, { useState, useEffect } from "react";
import "../../styles/privacy/privacy.css";
import brandBanner from "../../assets/common/brand_logo.png";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

const TermsContent = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: smooth scrolling behavior
    });
  };

  return (
    <>

          <div className="privacy-section">

              <div className="privacy-section-content">

                  <div className="privacy-main-heading">
                    Terms Of Service
                  </div>

                  <div className="privacy-heading-text-1">
                    Welcome to Giggle!
                  </div>

                  <div className="privacy-date-regular-text">
                      Giggle is owned, managed, and operated by Giggle LLC (hereinafter referred to as the “Owner”, “Us”, “We”, “Our” interchangeably and includes assigns, affiliates, subsidiaries, and successors in interest). <br /> <br />
                      If You have any questions or comments about these terms and conditions, please contact us (support@giggletoday.com). <br /> <br />
                      The Owner provides a platform marketed under the brand “Giggle” represented as “Giggle” or by way of any other representation. The platform allows its members to create unique personal profiles and communicate with other members to become Friends. <br /> <br />
                      These Terms govern and apply to Your access and use of Our service under “Giggle” will be available via Giggle’s Mobile Application and website www.giggletoday.com soon. The website, our services, and the Mobile Application can be accessed, downloaded, or used on any Device and are hereinafter collectively referred to as the “Service(s).” <br /> <br />
                      Please read these Terms of Use carefully, which include and hereby incorporate the Privacy Policy, Safety Tips, Community Guidelines, and any terms disclosed and agreed to by You if You purchase additional features, products, or services We offer on the Service (hereinafter collectively referred to as the “Terms”).
                  </div>

                  <div className="privacy-heading-text">
                    Acceptance of Terms of Use
                  </div>

                  <div className="privacy-date-regular-text">
                      By accessing or using our Services, you agree that (1) Your age is 16 years or above, and (2) You understand and agree to these Terms. Your access and use of the Services amount to Your irrevocable acceptance of and compliance with these Terms. <br /><br />
                      IF YOU DO NOT UNDERSTAND OR DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SERVICES. USE OF THE SERVICE IS VOID WHERE PROHIBITED.
                  </div>


                  <div className="privacy-heading-text">
                    Modification of Terms and Services
                  </div>

                  <div className="privacy-date-regular-text">
                      We may modify the Terms at any time, in our sole discretion. If We do so, We will update the Terms on the Services and/or notify You through reasonable means. You are responsible to be aware of and agree to abide by the Terms as published and periodically updated by Us. We urge You to access, review, and familiarize yourself with these Terms periodically, for your continued use of Services. <br /><br />
                      If you continue to use the Services after We have posted updated Terms, You are agreeing to be bound by the Terms as Updated. If You don’t agree to be bound by the updated Terms, then Your sole recourse is to discontinue the use of Services. <br /><br />
                      YOU AGREE THAT Owner SHALL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY AMENDMENT OR MODIFICATION TO THESE TERMS. <br /><br />
                      Except as may be expressly specified otherwise by Owner with respect to the paid portions of the Services, We reserve the right to add, change, suspend, or discontinue the Services, or any aspect or feature of the Services, without notice or liability.
                  </div>

                  <div className="privacy-heading-text">
                    Eligibility
                  </div>

                  <div className="privacy-date-regular-text">
                      You are not authorized to create an account or access and use the Service unless all of the following criteria are satisfied: <br /><br />
                      You are at least 16 years of age. You can form a binding contract with the Owner, You are not a person who is barred from using the Service, You will comply with these Terms and all applicable local, state, national and international laws, rules, and regulations, and You have never been convicted of a sex crime or any crime involving violence. <br /><br />
                      YOUR CREATION OF ACCOUNT IS A DECLARATION TO ALL OF THE ABOVE CRITERIA BEING SATISFIED AND IT IS YOUR SOLE RESPONSIBILITY TO ENSURE YOUR COMPLIANCE WITH THE ABOVE CRITERIA. THE Owner SHALL NOT BE HELD LIABLE IN CASE OF NON-COMPLIANCE WITH ALL OR ANY OF THE ABOVE CRITERIA AS THE Owner DOES NOT CONDUCT ANY BACKGROUND CHECK ON ITS MEMBERS.
                  </div>

                  <div className="privacy-heading-text">
                    Your Account
                  </div>

                  <div className="privacy-date-regular-text">
                      To access and use Our Services, You will have to create Your Account through Your mobile number either directly or through an invitation link shared with You. When You create an account with Us, You guarantee that the information You provide Us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of Your account on the Service. A single account can be created with a single mobile number. <br /> <br />
                      You are responsible for maintaining the confidentiality of your login credentials. You are solely responsible for all activities that occur under Your login credentials. If you become aware of unauthorized access to Your Account, then You are obligated to inform us immediately at the Support Email (support@giggletoday.com). <br /><br />
                      You shall not create multiple accounts to access and use Our Service. You shall not use as a username the name of another person or entity that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You, without appropriate authorization. You may not use as a username any name that is offensive, vulgar, or obscene. <br /><br />
                      We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel purchases at our sole discretion.
                  </div>

                  <div className="privacy-heading-text">
                    License to Use
                  </div>

                  <div className="privacy-date-regular-text">
                      Subject to the Prohibitions to Use under Section 8, the Owner grants You a personal royalty-free, non-assignable, nonexclusive, revocable, and non-sublicensable license to access and use the Service. This license is for the sole purpose of letting You access and use the Services as intended by the Owner under these Terms. <br /><br />
                      The Owner may investigate and take any available legal action in response to illegal or unauthorized uses of the Service, including termination of Your account.<br /><br />
                      The Service that we provide includes a Mobile Application that may automatically download and install upgrades, updates, or other new features. However, You can adjust these automatic downloads through Your device’s settings.<br /><br />
                      The License allows You to access and use the Service for the following without limitation:<br /><br />
                      - Create and maintain Your own Account with Your Personal Information and a Member Profile<br /><br />
                      - Post Content on Your own Profile<br /><br />
                      - Find a member using given criteria on the Service<br /><br />
                      - Like the Profile of a member<br /><br />
                      - Add a member as a Friend<br /><br />
                      - Delete a member from Friend’s List<br /><br />
                      - Block/Unblock a member<br /><br />
                      - Send invitations to others (Invitee) to avail Our Service<br /><br />
                      - Chat with a Friend or with a Group of Friends<br /><br />
                      - Find a Friend nearby<br /><br />
                      - Explore profiles of other Members before adding them as a Friend<br /><br />
                      -Explore and join communities<br /><br />
                      -Chat within communities<br /><br />
                      For the safety of its members, Giggle has a double opt-in feature that allows communication only when two members like each other’s profiles and have indicated mutual interest in friendship. However, Giggle takes no responsibility for the conduct of any member on or off of the Service. You agree to use caution in all interactions with other members, particularly if you decide to communicate off the Service or meet in person. In addition, you agree to review and follow the Safety Tips prior to using the Service. You agree that You will not provide Your financial information (for example, your credit card or bank account information), or wire or otherwise send money to other members.<br /><br />
                      YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS OR FINANCIAL TRANSACTIONS YOU MAKE. YOU UNDERSTAND THAT GIGGLE DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS MEMBERS. GIGGLE MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OR COMPATIBILITY OF MEMBERS.
                  </div>

                  <div className="privacy-heading-text">
                    Paid Features
                  </div>

                  <div className="privacy-date-regular-text">
                      If You wish to purchase any of the Paid Features made available through the Service (“Purchase”), You may be asked to supply certain financial information relevant to Your Purchase including, without limitation, your credit/debit card number, the expiration date, or details as required by the third-party payment service provider. <br /><br />
                      You represent and warrant that: (i) You have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct, and complete. <br /><br />
                      We may employ the use of a third-party payment service provider for the purpose of facilitating payment and the completion of Purchase. By submitting Your information, You grant us the right to provide the information to these third parties subject to our Privacy Policy. <br /><br />
                      We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, errors in Your order, or other reasons. <br /><br />
                      We reserve the right to refuse or cancel Your order if fraud or an unauthorized or illegal transaction is suspected. Generally, any payment made for a purchase on or through the Service or through a third-party payment service provider is non-refundable.
                  </div>

                  <div className="privacy-heading-text">
                    Content
                  </div>

                  <div className="privacy-date-regular-text">
                      Our Service allows You to post, link, store, share, and otherwise make available certain information, text, graphics, images, videos, or other material (“Content”). You are responsible for Content that You post on or through the Service, including its legality, reliability, and appropriateness. <br /><br />
                      By posting Content on or through the Service, You represent and warrant that: (i) Content is Yours (You own it) and/or You have the right to use it and the right to grant Us the rights and license as provided in these Terms, and (ii) that the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person or entity. We reserve the right to terminate Your account if any content is posted in violation of anyone’s legal rights.<br /><br />
                      You retain any and all of Your rights to any Content You submit, post, or display on or through the Service and You are responsible for protecting those rights. We take no responsibility and assume no liability for Content You or any third party posts on or through the Service.<br /><br />
                      However, by posting Content using the Service You grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You agree that this license includes the right for Us to make Your Content available to other members using the Service, who may also use Your Content subject to these Terms.<br /><br />
                      The Owner reserves the right to remove the Content posted by members that violates the Terms. Such Content is the sole responsibility of the member who posts it, and the Owner cannot guarantee that all Content will comply with the Terms. If You see Content on the Service that violates the Terms, please report it using the Support Email.<br /><br />
                      In addition, Content found on or through this Service is the property of the Owner or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from Us.<br /><br />
                      If You believe that Your work has been copied and posted on the Service in a way that constitutes copyright infringement, please submit a takedown request using the Support email.<br /><br />
                      If You contact us regarding alleged copyright infringement, please be sure to include the evidence of copyright interest along with other written submissions supporting your complaint.
                  </div>

                  <div className="privacy-heading-text">
                    Prohibited Use
                  </div>

                  <div className="privacy-date-regular-text">
                      You may use the Service only for lawful purposes and in accordance with the Terms. You agree not to misuse the Service. For example, You must not, and must not attempt to, use the Service to do the following things: <br /><br />
                      - Probe, scan, or test the vulnerability of any system or network;<br /><br />
                      - Breach or otherwise circumvent any security or authentication measures;<br /><br />
                      - Access, tamper with, or use non-public areas of the Service, shared areas of the Service You have not been invited to, or our computer systems;<br /><br />
                      - Interfere with or disrupt any member, host, or network, for example by sending a virus, overloading, flooding, spamming, or mail-bombing any part of the Service;<br /><br />
                      - Plant malware or otherwise use the Service to distribute malware;<br /><br />
                      - Access or search the Service by any means other than our publicly supported interfaces (for example, “scraping”);<br /><br />
                      - Send unsolicited communications, promotions, or advertisements, or spam;<br /><br />
                      - Send altered, deceptive, or false source-identifying information, including “spoofing” or “phishing”;<br /><br />
                      - Publish anything that is illegal, fraudulent, threatening, misleading, or infringing another’s rights or for a harmful purpose or activity;<br /><br />
                      - Promote or advertise products or Services without appropriate authorization;<br /><br />
                      - Impersonate or misrepresent your affiliation with any person or entity;<br /><br />
                      - Publish or share materials that are unlawfully pornographic or indecent, or that advocate bigotry, religious, racial, or ethnic hatred;<br /><br />
                      - Violate any applicable national or international law or regulation, or to violate the privacy of others, or to defame others;<br /><br />
                      - Exploit, harm, or attempt to exploit or harm minors in any way by exposing them to inappropriate content or otherwise;<br /><br />
                      - Engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us, may harm or offend Owner or members using the Service or expose them to liability;<br /><br />
                      - Violate our Community Guidelines, as updated from time to time;<br /><br />
                      - Spam, solicit money from, or defraud any members;<br /><br />
                      - Solicit passwords for any purpose or personal identifying information for commercial or unlawful purposes from other members or disseminate another person’s personal information without his or her permission;<br /><br />
                      - Use another member’s account, share an account with another member, or create or maintain more than one account;<br /><br />
                      - Create another account if we have already terminated your account, unless you have our permission;<br /><br />
                      - Use the Service or any content contained in the Service for any commercial purposes without our written consent;<br /><br />
                      - Copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, images, trademarks, trade names, service marks, or other intellectual property, content, or proprietary information accessible through the Service without Owner’s prior written consent;<br /><br />
                      - Express or imply that any statements you make are endorsed by the Owner;<br /><br />
                      - Use meta tags or code or other devices containing any reference to the Owner or the Service (or any trademark, trade name, service mark, logo, or slogan of the Owner) to direct any person to any other website for any purpose;<br /><br />
                      - Modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile, or otherwise disassemble any portion of the Service, or cause others to do so;<br /><br />
                      - Use or develop any third-party applications that interact with the Service or other members’ Content or information without our written consent;<br /><br />
                      - Use the Service in any way that could interfere with, disrupt, or negatively affect the Service or the servers or networks connected to the Service;<br /><br />
                      - Use, access, or publish the Owner’s application programming interface without our written consent
                  </div>

                  <div className="privacy-heading-text">
                    Termination and Survival
                  </div>

                  <div className="privacy-date-regular-text">
                      We may terminate or suspend Your Account and bar access to the Service immediately, without prior notice or liability, under Our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms. <br /><br />
                      If You wish to terminate Your account, You may simply discontinue using Our Service. <br /><br />
                      After Your account is terminated, the following provisions shall survive the termination: Limitation of Liability, Indemnification, Intellectual Property Rights, and Warranty Disclaimer.
                  </div>

                  <div className="privacy-heading-text">
                    Communications
                  </div>

                  <div className="privacy-date-regular-text">
                    By creating an Account on Our Service, You agree to subscribe to newsletters, marketing, or promotional materials and other information We may send. However, You may opt out of receiving any, or all, of these communications from Us by clicking the unsubscribe link or by mailing on Support Email (support@giggletoday.com).
                  </div>

                  <div className="privacy-heading-text">
                    Intellectual Property Rights
                  </div>

                  <div className="privacy-date-regular-text">
                    The Service and its original content (excluding Content provided by members), features, and functionality are and will remain the exclusive property of the Owner. The Service is protected under copyright, trademark, and other laws of India, International laws, and laws of foreign territories. Our trademarks and trade dress shall not be used in connection with any product or service without Our prior written consent
                  </div>

                  <div className="privacy-heading-text">
                    Warranty Disclaimer
                  </div>

                  <div className="privacy-date-regular-text">
                      THE SERVICE IS PROVIDED BY OWNER ON AN “AS IS” AND “AS AVAILABLE” BASIS. OWNER MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK. <br /><br />
                      NEITHER OWNER NOR ANY PERSON ASSOCIATED WITH OWNER MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER OWNER NOR ANYONE ASSOCIATED WITH OWNER REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.<br /><br />
                      OWNER HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.<br /><br />
                      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </div>

                  <div className="privacy-heading-text">
                    Third Party Services
                  </div>

                  <div className="privacy-date-regular-text">
                    Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be directed to that third party’s site. We strongly advise You to review the Terms and/or Privacy Policy or other supplementary rules/guidelines of every site You visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. You further acknowledge and agree that the Owner shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
                  </div>

                  <div className="privacy-heading-text">
                    Limitation of Liability
                  </div>

                  <div className="privacy-date-regular-text">
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE Owner, ITS AFFILIATES, EMPLOYEES, LICENSORS, OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE, (II) THE CONDUCT OR CONTENT OF OTHER MEMBERS OR THIRD PARTIES ON, THROUGH, OR FOLLOWING USE OF THE SERVICE; OR (III) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF THE Owner HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL THE Owner’s AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICE EXCEED THE GREATER OF THE AMOUNT PAID, IF ANY, BY YOU TO THE Owner FOR THE SERVICE.
                  </div>

                  <div className="privacy-heading-text">
                    Indemnification
                  </div>

                  <div className="privacy-date-regular-text">
                    You agree, to the extent permitted under applicable law, to indemnify, defend, and hold harmless the Owner, its affiliates, and their respective officers, directors, agents, and employees from and against any and all complaints, demands, claims, damages, losses, costs, liabilities, and expenses, including attorney’s fees due to, arising out of, or relating in any way to your access to or use of the Service, your Content, or your breach of these Terms.
                  </div>

                  <div className="privacy-heading-text">
                    Governing Law and Dispute Resolution
                  </div>

                  <div className="privacy-date-regular-text">
                    The Terms shall be governed by and construed in accordance with the laws of India, without regard to conflict of law principles.
                  </div>

                  <div className="privacy-heading-text">
                    General
                  </div>

                  <div className="privacy-date-regular-text">
                      No waiver by Owner of any term or condition set forth in the Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Owner to assert a right or provision under Terms shall not constitute a waiver of such right or provision. <br /><br />
                      If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.<br /><br />
                      The headings contained in these Terms are for reference only and shall not affect the meaning of any of the provisions of these Terms.<br /><br />
                      These Terms, which include the Privacy Policy, Safety Tips, Community Guidelines, and any terms disclosed and agreed to by You if you purchase additional features, products, or services we offer on the Service, contain the entire agreement between You and the Owner regarding the use of the Service and is valid and enforceable.<br /><br />
                      You agree that Your account is non-transferable and all of Your rights to Your account and its Content terminate upon Your death. No agency, partnership, joint venture, fiduciary, or other special relationship or employment is created as a result of Your agreement to these terms and You may not make any representations on behalf of or bind the Owner in any manner.
                  </div>

                  <div className="privacy-heading-text">
                    Feedback
                  </div>

                  <div className="privacy-date-regular-text">
                      You may provide us feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”) on Support Email. <br /><br />
                      You assign all rights, title, and interest in any Feedback You provide to the Owner. If for any reason such assignment is ineffective, You agree to grant the Owner a non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify, and exploit such Feedback without restriction.
                  </div>

                  <div className="privacy-heading-text">
                    Contact us
                  </div>

                  <div className="privacy-date-regular-text">
                      Please send your feedback, comments, requests for technical support: <br /><br />
                      Support Email: support@giggletoday.com
                  </div>

              </div>


          </div>

          <Divider sx={{ marginTop: '5%', width: '100%'}}/>

    </>
  );
};

export default TermsContent;