import React, { useState, useEffect } from "react";
import "../styles/footer/footer.css";
import footerCircle from "../assets/common/footer_circle.png";
import footerFlowers from "../assets/common/footer_flowers.png";
import x from "../assets/common/x.png";
import faceboook from "../assets/common/facebook.png";
import instagram from "../assets/common/instagram.png";
import linkedin from "../assets/common/linkedin.png";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';

const CommonFooter = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: smooth scrolling behavior
    });
  };

  const handleImageClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>

    <div className="footer-section">

        {/* <img src={footerCircle} alt="" className="home-footer"/> */}

        <div className="footer-parent-row">
            <div className="footer-main-row">

            <div className="home-copy-right-text">
                          <div className="footer-social-row">
                              <img src={instagram} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.instagram.com/giggle.today/")} />
                              <img src={faceboook} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.facebook.com/giggle.x.today/")} />
                              <img src={x} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://twitter.com/giggle_today/")} />

                              <img src={linkedin} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.linkedin.com/company/giggle-today/")} />

                          </div>
                          2024 Giggle LLC, All Rights Reserved.
                      </div>

                <div className="footer-links-column">

                    <div className="footer-links" onClick={() => navigate(`/support`)}>
                        Support
                    </div>
                    
                    <div className="footer-links" onClick={()=> navigate(`/careers`)}>
                        Careers
                    </div>

                </div>

                <div className="footer-links-column">

                    <div className="footer-links" onClick={()=> navigate(`/privacy`)}>
                        Privacy Policy
                    </div>
                    
                    <div className="footer-links-1" onClick={() => navigate(`/ambassadors`)}>
                        Ambassadors
                    </div>

                </div>

                <div className="footer-links-column-1">

                    <div className="footer-links" onClick={()=> navigate(`/terms`)}>
                        Terms Of Services
                    </div>
                    
                    <div className="footer-links" onClick={()=> navigate(`/guidelines`)}>
                        Community Guidelines
                    </div>

                </div>

            </div>

            <div className="mob-footer-main-row">

                      <div className="footer-link-row">

                          <div className="footer-links-column">

                              <div className="footer-links" onClick={() => navigate(`/support`)}>
                                  Support
                              </div>

                              <div className="footer-links" onClick={()=> navigate(`/careers`)}>
                                  Careers
                              </div>

                              <div className="footer-links" onClick={() => navigate(`/terms`)}>
                                  Terms Of Services
                              </div>

                          </div>

                          <div className="footer-links-column">

                              <div className="footer-links" onClick={() => navigate(`/privacy`)}>
                                  Privacy Policy
                              </div>

                              <div className="footer-links-1" onClick={() => navigate(`/ambassadors`)}>
                                  Ambassadors
                              </div>

                              <div className="footer-links" onClick={() => navigate(`/guidelines`)}>
                                  Community Guidelines
                              </div>

                          </div>

                      </div>

                      <div className="home-copy-right-text">
                          <div className="footer-social-row">
                              <img src={instagram} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.instagram.com/giggle.today/")} />
                              <img src={faceboook} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.facebook.com/giggle.x.today/")} />
                              <img src={x} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://twitter.com/giggle_today/")} />

                              <img src={linkedin} alt="" className="footer-social-icons" onClick={() => handleImageClick("https://www.linkedin.com/company/giggle-today/")} />

                          </div>
                          2024 Giggle LLC, All Rights Reserved.
                      </div>

            </div>
        </div>

        <img src={footerFlowers} alt="" className="home-footer"/>

    </div>

    </>
  );
};

export default CommonFooter;