import React, { useState, useEffect, useRef } from "react";
import "../../styles/ambassadors_page/ambassadors_page.css";
import AmbassadorsBanner from "../../assets/ambassadors_page/ambassadors_banner.png";
import MobmbassadorsBanner from "../../assets/ambassadors_page/mob_ambassadors_banner.png";
import ultimate from "../../assets/ambassadors_page/ultimate.png";
import AmbassadorsCarousal from "../../components/ambassadors_page/ambassadors_carousal";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import brandBanner from "../../assets/common/brand_logo.png";
import banner from "../../assets/common/banner.png";
import ham from "../../assets/common/ham.png";
import close from "../../assets/common/close.png";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AmbassadorSection = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();

  const carousalRef = useRef(null);

  // Scroll to carousal function
  const scrollToCarousal = () => {
    if (carousalRef.current) {
      carousalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const [email, setEmail] = useState('');
  const [displayName, setName] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [dob, setDOB] = useState('');
  const [instagram, setInsta] = useState('');

  const [isLoading, setIsLoading] = useState(false);

    const handleGiggler = () => {
      setIsLoading(true);
        // Perform API call to join the waitlist using Axios
        axios.post('https://prod.giggletoday.com/helpers/team/', { mail: email, name: displayName, phone: phoneNumber, dob: dob, social_media: instagram })
            .then(response => {
                if (response.status === 200) {
                  setEmail('');
                  setName('');
                  setPhone('');
                  setInsta('');
                  setDOB('');
                  setIsLoading(false);
                } else {
                    // Handle error responses
                    console.error('Error joining waitlist');
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error joining waitlist:', error);
                setIsLoading(false);
            });
    };

  return (
    <>

        <div className="ambassadors-section">
            <img src={AmbassadorsBanner} alt="" className="ambassadors-header"/>
            <img src={MobmbassadorsBanner} alt="" className="mob-ambassadors-header"/>

        <div className="ambassador-header-row">
          <img src={banner} alt="" className="ambassador-header-section-banner" onClick={() => navigate(`/`)}/>

          <div className="ambassador-header-link-row">
            <div className="ambassador-header-link" onClick={() => navigate(`/support`)}>
              Support
            </div>

            <div className="ambassador-header-link" onClick={() => navigate(`/careers`)}>
              Careers
            </div>



            <div className="ambassador-header-link" onClick={() => navigate(`/ambassadors`)}>
              Ambassadors
            </div>
          </div>

          <img src={ham} alt="" className="ambassador-header-section-ham" onClick={handleDrawerOpen}/>

        </div>

            <div className="ambassador-mob-header-row">

            </div>

        <div className="ambassadors-main-row">

          <div className="ambassador-main-heading">
            Impact The Community
          </div>

          <div className="ambassador-main-column">
            <div className="ambassador-main-description">
              Giggle is your launchpad for building genuine friendships and fostering a connected community of shared interests. A world where everyone feels heard, valued, and supported in their life.
            </div>

            <div className="ambassador-main-button" onClick={scrollToCarousal}>
                Apply Now
            </div>
          </div>

        </div>
        <AmbassadorsCarousal />

        <div className="ultimate-section" ref={carousalRef}>

          <div className="ultimate-row">

            <img src={ultimate} alt="" className="ultimate-img"/>

            <div className="ultimate-column">

              <div className="ultimate-column-heading">
                Are You the Ultimate Giggler?
              </div>

              <div className="ultimate-column-subheading">
              Yes, if you're social media-obsessed and boast a strong online presence.
              <br /> Yes, if you're a bold trendsetter always ready to make the first move.

              </div>

            </div>

          </div>

        </div>



            {/* <div className="ambassadors-second-column">

              <div className="ambassador-second-heading">
                What Makes You a giggler?
              </div>

              <div className="ambassador-second-description">
                Our Gigglers spread joy and connection throughout their communities, promoting our values of inclusivity and positivity. As a Giggler, you'll empower your peers to forge meaningful friendships and connections using our app, while championing a culture of kindness and acceptance.Join us in challenging stereotypes and breaking down barriers to friendship. Apply to become a Giggler today and start making moves towards a happier, more connected world. Questions before applying? Reach out to us anytime – we're here to support you every step of the way.
              </div>

            </div> */}

        <div className="ambassadors-form-row-main">

          <div className="ambassadors-form-row">

            <div className="ambassador-form-heading">
              Ready To Join the Team?
            </div>

            <div className="ambassadors-form-column">

              <div className="ambassador-text-field-container">
                <input type="text" className="textfield" placeholder="Full Name*"  value={displayName} onChange={(e) => setName(e.target.value)} />
              </div>

              <div className="ambassador-text-field-container">
                <input type="text" className="textfield" placeholder="Email Address*" value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>

              <div className="ambassador-text-field-container">
                <input type="text" className="textfield" placeholder="Phone Number*" value={phoneNumber} onChange={(e) => setPhone(e.target.value)}/>
              </div>

              {/* <div className="ambassador-text-field-container">
      <input type="text" className="textfield" placeholder="Date Of Birth" />
  </div> */}

              <div className="ambassador-text-field-container">
                <input type="text" className="textfield" placeholder="Instagram URL" value={instagram} onChange={(e) => setInsta(e.target.value)}/>
              </div>

              <div className="ambassador-apply-button-row">

                {isLoading ? (
                  <div className="ambassador-apply-button">
                    {/* Loader component or spinner */}
                    <div className="ambassador-loader"></div>
                  </div>
                ) : (
                  <div className="ambassador-apply-button" onClick={handleGiggler}>
                    Apply
                  </div>
                )}

               

              </div>

            </div>

          </div>

        </div>
            
            

            <Divider sx={{width: '80%', marginTop: '50px'}}/>

            <Drawer
                  sx={{
                      width: '75%',
                      flexShrink: 0,
                      '& .MuiDrawer-paper': {
                          width: '75%',
                      },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={open}
              >

                  <div className="career-header-drawer-first-row">

                      <img src={brandBanner} alt="" className="header-drawer-banner" />
                      <img src={close} alt="" className="header-drawer-close" onClick={handleDrawerClose} />

                  </div>

                  <Divider sx={{ marginTop: '30%', width: '80%', marginLeft: '30px' }} />

                  <div className="header-drawer-link" onClick={() => navigate(`/support`)}>
                      Support
                  </div>

                  <div className="header-drawer-link" onClick={()=> navigate(`/careers`)}>
                      Careers
                  </div>

                  <div className="header-drawer-link">
                      Ambassadors
                  </div>




              </Drawer>

        </div>

    </>
  );
};

export default AmbassadorSection;