import React from "react";
import "../../styles/home_page/home_page.css";
import background from "../../assets/home_page/home_sec_2.png";
import mobBackground from "../../assets/home_page/mob_home_sec_2.png";
import mockups from "../../assets/home_page/home_2_mockup.png";
import rightEarth from "../../assets/home_page/home_2_right_globe.png";
import mobBelowEarth from "../../assets/home_page/mob_below_earth.png";

const Feature1Section = () => {

  return (
    <>

      <div className="feature-section-1">

        <img src={background} alt="" className="feature-section-1-background" />
        <img src={mockups} alt="" className="home-2-mockups" />
        <img src={rightEarth} alt="" className="home-2-right-earth" />

        <div className="home-2-text-column">

          <div className="home-2-text-heading">
            Find like Minded Friends!
          </div>

          <div className="home-2-text-subheading">
            Match with people who share your interests & personality traits.
          </div>

        </div>


      </div>

      <div className="mob-feature-section-1">

        <img src={mobBelowEarth} alt="" className="mob-below-earth" />
        <img src={mobBackground} alt="" className="feature-section-1-background" />
        <img src={mockups} alt="" className="mob-home-2-mockups" />

        <div className="home-2-text-column">
          <div className="home-2-text-heading">
            Find like Minded Friends!
          </div>

          <div className="home-2-text-subheading">
            Match with people who share your interests & personality traits.
          </div>
        </div>

      </div>

    </>
  );
};

export default Feature1Section;