import React from "react";
import "../../styles/home_page/home_page.css";
import leftEarth from "../../assets/home_page/left_earth.png";
import background from "../../assets/home_page/home_sec_3.png";
import mobBackground from "../../assets/home_page/mob_home_sec_3.png";
import mockups from "../../assets/home_page/home_3_mockup.png";

const Feature2Section = () => {

  return (
    <>

      <div className="feature-section-2">

        <img src={background} alt="" className="feature-section-2-background" />
        <img src={mockups} alt="" className="home-3-mockups" />
        <img src={leftEarth} alt="" className="home-3-left-earth" />

        <div className="home-3-text-column">

          <div className="home-3-text-heading">
            Chill out and vibe!
          </div>

          <div className="home-3-text-subheading">
            Events to bring together your tribe of friends who share your interests and values
          </div>

        </div>


      </div>

      <div className="mob-feature-section-2">

        <img src={mobBackground} alt="" className="feature-section-2-background" />
        <img src={mockups} alt="" className="home-3-mockups" />
        <img src={leftEarth} alt="" className="home-3-left-earth" />


        <div className="home-3-text-column">

          <div className="home-3-text-heading">
            Chill out and vibe!
          </div>

          <div className="home-3-text-subheading">
            Events to bring together your tribe of friends who share your interests and values
          </div>

        </div>

      </div>

    </>
  );
};

export default Feature2Section;