import React, { useState, useEffect } from "react";
import "../../styles/home_page/home_page.css";
import header from "../../assets/home_page/web_first.png";
import foreHeader from "../../assets/home_page/web_header.png";
import banner from "../../assets/common/banner.png";
import ham from "../../assets/common/ham.png";
import mobHeader from "../../assets/home_page/mob_first.png";
import mobForeHeader from "../../assets/home_page/mob_header.png";
import Drawer from '@mui/material/Drawer';
import brandBanner from "../../assets/common/brand_logo.png";
import close from "../../assets/common/close.png";
import Divider from '@mui/material/Divider';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import apiDone from "../../assets/home_page/done_api.png";
import playstore from "../../assets/common/playstore.png";
import appstore from "../../assets/common/appstore.png";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HeaderSection = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [showWaitlist, setShowWaitlist] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    const handleJoinWaitlist = () => {
        setIsLoading(true);
        // Perform API call to join the waitlist using Axios
        axios.post('https://prod.giggletoday.com/helpers/post_waitlist/', { mail: email })
            .then(response => {
                if (response.status === 200) {
                    setShowWaitlist(false); // Hide waitlist form
                    setShowSuccess(true); // Show success message
                    setIsLoading(false);
                } else {
                    // Handle error responses
                    console.error('Error joining waitlist');
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error joining waitlist:', error);
                setIsLoading(false);
            });
    };

    const handlePlaystoreClick = (event) => {
      event.preventDefault();
      console.log('Image was clicked!');
      window.open('https://play.google.com/store/apps/details?id=com.schaffen.giggle', '_blank');
    };


    const handleAppstoreClick = (event) => {
      event.preventDefault();
      window.open('https://apps.apple.com/us/app/giggle-make-friends-chat/id6498945325', '_blank');
    };


  return (
    <>
        <ToastContainer />

        <div className="header-section">
          <img src={header} alt="Bottom Image" className="header-section-background" />
          <img src={foreHeader} alt="Overlay Image" className="header-section-foreground" />
          <img src={banner} alt="Banner Image" className="header-section-banner" />

          <div className="header-link-row">

            <div className="header-link-style" onClick={() => navigate(`/support`)}>
              Support
            </div>

            <div className="header-link-style" onClick={() => navigate(`/careers`)}>
              Careers
            </div>

            

            <div className="header-link-style" onClick={() => navigate(`/ambassadors`)}>
              Ambassadors
            </div>

          </div>

          <div className="header-main-column">

          <div className="header-heading-text">
            Where friendship <br /> finds you..
          </div>

          <div className="download-links-row">
              <img src={playstore} alt="" className="playstore-banner" onClick={handlePlaystoreClick}/>
              <img src={appstore} alt="" className="appstore-banner-footer" onClick={handleAppstoreClick}/>
           </div>

          {/* {showWaitlist && <div className="header-waitlist-container">

            <input
              type="text"
              className="header-waitlist-text-field"
              placeholder="Your E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <div className="header-waitlist-btn" onClick={handleJoinWaitlist}>
              {isLoading ? (
                <div className="home-loader"></div>
              ) : (
                "Join Waitlist"
              )}

            </div>

          </div>}

          {showSuccess && (
            <img src={apiDone} alt="Success" className="home-waitlist-done" />
          )} */}

          

          </div>

          

        </div>

        <div className="mob-header-section">
          <img src={mobHeader} alt="Bottom Image" className="header-section-background" />
          <img src={mobForeHeader} alt="Overlay Image" className="header-section-foreground" />
          <div className="mob-header-banner-row">
            <img src={banner} alt="Banner Image" className="header-section-banner" />
            <img src={ham} alt="" className="header-section-ham" onClick={handleDrawerOpen}/>
          </div>

          <div className="header-main-column">

          <div className="header-heading-text">
            Where friendship <br /> finds you..
          </div>

          <div className="download-links-row">
              <img src={playstore} alt="" className="playstore-banner" onClick={handlePlaystoreClick}/>
              <img src={appstore} alt="" className="appstore-banner-footer" onClick={handleAppstoreClick}/>
           </div>

          {/* {showWaitlist && <div className="header-waitlist-container">

            <input
              type="text"
              className="header-waitlist-text-field"
              placeholder="Your E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <div className="header-waitlist-btn" onClick={handleJoinWaitlist}>
              Join Waitlist
            </div>

          </div>}

          {showSuccess && (
            <img src={apiDone} alt="Success" className="home-waitlist-done" />
          )} */}

          </div>

          <Drawer
           sx={{
            width: '75%',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: '75%',
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
          >

            <div className="header-drawer-first-row">

              <img src={brandBanner} alt="" className="header-drawer-banner"/>
              <img src={close} alt="" className="header-drawer-close" onClick={handleDrawerClose}/>

            </div>

            <Divider sx={{marginTop: '30%', width: '80%', marginLeft: '30px'}}/>

            <div className="header-drawer-link" onClick={() => navigate(`/support`)}>
              Support
            </div>

            <div className="header-drawer-link" onClick={() => navigate(`/careers`)}>
              Careers
            </div>

            <div className="header-drawer-link" onClick={() => navigate(`/ambassadors`)}>
              Ambassadors
            </div>




          </Drawer>



        </div>

    </>
  );
};

export default HeaderSection;