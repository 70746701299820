import React from "react";
import "../../styles/home_page/home_page.css";
import background from "../../assets/home_page/home_sec_1.png";
import mobBackground from "../../assets/home_page/mob_home_sec_1.png";
import mockups from "../../assets/home_page/home_1_mockup.png";
import rightEarth from "../../assets/home_page/home_1_right_globe.png";
import aboveEarth from "../../assets/home_page/upper_earth.png";

const ShowCaseSection = () => {

  return (
    <>

    <div className="showcase-section">

      <img src={background} alt="" className="showcase-background"/>
      <img src={mockups} alt="" className="home-1-mockups"/>
      <img src={rightEarth} alt="" className="home-right-earth"/>

      <div className="home-1-text-column">

        <div className="home-1-text-heading">
          Your tribe here!
        </div>

        <div className="home-1-text-subheading">
          Connect with People Nearby, Explore Your Tribe and Chat Worldwide!
        </div>

      </div>


    </div>

    <div className="mob-showcase-section">

      <img src={mobBackground} alt="" className="showcase-background"/>
      <img src={mockups} alt="" className="home-1-mockups"/>
      <img src={aboveEarth} alt="" className="above-earth"/>

      <div className="home-1-text-column">

        <div className="home-1-text-heading">
          Your tribe here!
        </div>

        <div className="home-1-text-subheading">
          Connect with People Nearby, Explore Your Tribe and Chat Worldwide!
        </div>

      </div>


    </div>

        {/* <div className="showcase-section">

            <img src={waterfall} alt="" className="showcase-waterfall"/>

            <div className="floating-row">

            <img src={showcase3} alt="" className="floating"/>
            <img src={showcase1} alt="" className="floating-1"/>
            <img src={showcase2} alt="" className="floating-2"/>

            </div>

            

            <img src={aboveEarth} alt="" className="above-earth"/>

        <div className="showcase-heading-column">

          <div className="showcase-heading">
            Your <br /> Tribe <br /> Here
          </div>

          <div className="showcase-subheading">
            Connect with People Nearby, Explore Your Tribe and Chat Worldwide!
          </div>

        </div>

            

        </div>

        <div className="mob-showcase-section">

            <img src={mobWaterfall} alt="" className="mob-showcase-waterfall"/>

            <div className="showcase-heading">
              Your <br /> Tribe <br /> Here
            </div>

            <div className="floating-row">
                <img src={showcase3} alt="" className="floating"/>
                <img src={showcase1} alt="" className="floating-1"/>
                <img src={showcase2} alt="" className="floating-2"/>
            </div>

            <img src={aboveEarth} alt="" className="above-earth"/>


            <div className="showcase-subheading">
              Connect with People Nearby, Explore Your Tribe and Chat Worldwide!
            </div>

        </div> */}

    </>
  );
};

export default ShowCaseSection;