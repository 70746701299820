import React from "react";
import "../../styles/home_page/home_page.css";
import background from "../../assets/home_page/home_sec_4.png";
import mobBackground from "../../assets/home_page/mob_home_sec_4.png";
import mockups from "../../assets/home_page/home_4_mockup.png";
import rightEarth from "../../assets/home_page/home_1_right_globe.png";


const Feature3Section = () => {

  return (
    <>

      <div className="feature-section-3">

        <img src={background} alt="" className="feature-section-1-background" />
        <img src={mockups} alt="" className="home-4-mockups" />
        <img src={rightEarth} alt="" className="home-3-right-earth" />

        <div className="home-4-text-column">

          <div className="home-4-text-heading">
            Hit me up!
          </div>

          <div className="home-4-text-subheading">
            Spark spontaneous hangouts by hitting up nearby friends.
          </div>

        </div>


      </div>


      <div className="mob-feature-section-3">

        <img src={mobBackground} alt="" className="feature-section-3-background" />
        <img src={mockups} alt="" className="home-4-mockups" />

        <div className="home-4-text-column">

          <div className="home-4-text-heading">
            Hit me up!
          </div>

          <div className="home-4-text-subheading">
            Spark spontaneous hangouts by hitting up nearby friends.
          </div>

        </div>

      </div>

    </>
  );
};

export default Feature3Section;