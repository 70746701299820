import React, { useState, useEffect } from "react";
import "../../styles/privacy/privacy.css";
import AmbassadorsHeader from "../../components/ambassadors_page/ambassadors_header"
import CommonFooter from "../../components/footer";

const AmbassadorsPage = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  return (
    <>  
        <AmbassadorsHeader />
        <CommonFooter />
    </>
  );
};

export default AmbassadorsPage;