import "./App.css";
import Home from "./pages/home_page/home_page";
import Privacy from "./pages/privacy_page/privacy";
import Terms from "./pages/terms_page/terms";
import GuideLines from "./pages/guidelines_page/guidelines";
import Support from "./pages/support_page/support";
import Careers from "./pages/careers_page/careers_page";
import AmbassadorsPage from "./pages/ambassadors_page/ambassadors_page";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/guidelines" element={<GuideLines />} />
          <Route path="/support" element={<Support />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/ambassadors" element={<AmbassadorsPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
