import React, { useState, useEffect } from "react";
import "../../styles/privacy/privacy.css";
import brandBanner from "../../assets/common/brand_logo.png";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';

const GuidelineContent = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: smooth scrolling behavior
    });
  };

  return (
    <>

          <div className="privacy-section">

              <div className="privacy-section-content">

                  <div className="privacy-main-heading">
                    Community Guidelines
                  </div>

                  <div className="privacy-heading-text-1">
                    Welcome to the Giggle community! 
                  </div>

                  <div className="privacy-date-regular-text-1">
                    We're thrilled to have you join us. To ensure a positive and enjoyable experience for all users, we ask that you adhere to the following community guidelines. By participating in the Giggle community, you agree to abide by these rules both on and offline. Let's create a space filled with laughter, kindness, and genuine connections!
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>1. Be Respectful and Kind:</strong> Keep your interactions and content classy and appropriate for all audiences. Avoid posting nudity, sexually explicit content, or anything that could be considered offensive or inappropriate.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>2. No Offensive Content: </strong> Treat others with respect, kindness, and empathy. Remember, behind every profile is a real person with feelings. Let's build each other up and spread positivity.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>3. Zero Tolerance for Harassment:</strong> Harassment of any kind, including bullying, stalking, threats, or unsolicited sexual content, will not be tolerated. Everyone deserves to feel safe and respected within the Giggle community.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>4. Violence and Harm:</strong> Advocating for or glorifying violence, including physical harm or self-harm, is strictly prohibited. Let's keep Giggle a safe and welcoming space for all users.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>5. No Hate Speech:</strong> We do not tolerate hate speech or discrimination of any kind. Respect diversity and celebrate differences. Any content promoting racism, bigotry, or hatred will not be tolerated.
                  </div>


                  <div className="privacy-date-regular-text">
                    <strong>6. Protect Privacy:</strong> Respect the privacy of others and refrain from sharing any private or sensitive information, including contact details, financial information, or personal addresses.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>7. Genuine Engagement:</strong> Be genuine in your interactions and avoid spamming or soliciting other users. Giggle is about building authentic connections, not promoting external websites or businesses.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>8. Prohibited Activities:</strong> Do not engage in illegal activities or promote illegal behavior on Giggle. This includes scamming, impersonation, or any other form of fraudulent activity.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>9. Be Yourself:</strong> Authenticity is key on Giggle. Represent yourself truthfully and refrain from impersonating others or misrepresenting your identity.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>10. Age Requirement:</strong> You must be 16 years of age or older to use Giggle. We do not allow images of unaccompanied minors or any content that may harm or exploit minors.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>11. Intellectual Property:</strong> Respect copyright and trademark laws. Only post content that you have the right to share, and refrain from infringing on the intellectual property rights of others.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>12. One Account Per User:</strong> Each user is allowed only one Giggle account. Multiple accounts or account sharing are not permitted.
                  </div>

                  <div className="privacy-date-regular-text">
                    <strong>13. Account Activity:</strong> Stay active on Giggle to fully enjoy the experience. Accounts inactive for an extended period may be subject to deletion.
                  </div>

                  <div className="privacy-date-regular-text">
                    Thank you for being a part of the Giggle community! If you encounter any behavior that violates these guidelines, please report it to us immediately. Let's create a vibrant and welcoming community where laughter and genuine connections thrive!
                  </div>

                  <div className="privacy-heading-text-1">
                    Reporting
                  </div>

                  <div className="privacy-date-regular-text">
                    As a member of the Giggle community, we value your voice and your safety. If you encounter behavior that causes harm, discomfort, or violates our Community Guidelines, we encourage you to speak up and report it. Your report is confidential, and it helps us maintain a positive and welcoming environment for all users. <br /><br />
                    By reporting, you play a crucial role in stopping harmful behavior and protecting others. Together, we can ensure that Giggle remains a place where genuine connections and shared laughter thrive. Thank you for your commitment to creating a safe and inclusive community.
                  </div>
                 


              </div>


          </div>

          <Divider sx={{ marginTop: '5%', width: '100%'}}/>

    </>
  );
};

export default GuidelineContent;