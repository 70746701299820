import React, { useState, useEffect } from "react";
import "../../styles/privacy/privacy.css";
import brandBanner from "../../assets/common/brand_logo.png";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import close from "../../assets/common/close.png";
import Divider from '@mui/material/Divider';
import ham from "../../assets/common/black_ham.png";
const PrivacyHeader = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: smooth scrolling behavior
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>

          <div className="privacy-section">

              <div className="privacy-section-main">

                  <img src={brandBanner} alt="" className="privacy-banner" onClick={()=> navigate(`/`)}/>

                  <div className="privacy-header-link-row">

                      <div className="privacy-header-link" onClick={()=> navigate(`/support`)}>
                          Support
                      </div>

                      <div className="privacy-header-link" onClick={()=> navigate(`/careers`)}>
                          Careers
                      </div>

                      <div className="privacy-header-link" onClick={() => navigate(`/ambassadors`)}>
                          Ambassadors
                      </div>

                  </div>

                  <img src={ham} alt="" className="privacy-header-section-ham" onClick={handleDrawerOpen}/>
                  
                  <div className="privacy-mob-drawer">
                      <Drawer
                          sx={{
                              width: '75%',
                              flexShrink: 0,
                              '& .MuiDrawer-paper': {
                                  width: '75%',
                              },
                          }}
                          variant="persistent"
                          anchor="right"
                          open={open}
                      >

                          <div className="privacy-header-drawer-first-row">

                              <img src={brandBanner} alt="" className="header-drawer-banner" />
                              <img src={close} alt="" className="header-drawer-close" onClick={handleDrawerClose} />

                          </div>

                          <Divider sx={{ marginTop: '30%', width: '80%', marginLeft: '30px' }} />

                          <div className="header-drawer-link" onClick={() => navigate(`/support`)}>
                              Support
                          </div>

                          <div className="header-drawer-link" onClick={()=> navigate(`/careers`)}>
                              Careers
                          </div>

                          <div className="header-drawer-link" onClick={() => navigate(`/ambassadors`)}>
                              Ambassadors
                          </div>




                      </Drawer>
                  </div>
              </div>

              


          </div>

    </>
  );
};

export default PrivacyHeader;