import React, { useState, useEffect } from "react";
import "../../styles/privacy/privacy.css";
import caraousalBackground from "../../assets/ambassadors_page/carousal_background.png";
import firstCarousal from "../../assets/ambassadors_page/first_carousal.png";
import secondCarousal from "../../assets/ambassadors_page/second_carousal.png";
import thirdCarousal from "../../assets/ambassadors_page/third_carousal.png";
import fourthCarousal from "../../assets/ambassadors_page/fourth_carousal.png";
import fifthCarousal from "../../assets/ambassadors_page/fifth_carousal.png";
import sixthCarousal from "../../assets/ambassadors_page/sixth_carousal.png";
import rightCheveron from "../../assets/ambassadors_page/right_cheveron.png";
import leftCheveron from "../../assets/ambassadors_page/left_cheveron.png";

const AmbassadorsCarousal = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLeftChevronClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleRightChevronClick = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, 5));
  };

  return (
    <>

      <img src={caraousalBackground} alt="" className="ambassador-carousal-background" />

      <div className="ambassador-carousal-column">

        <div className="ambassador-carousal-heading">
          Why Become a Giggle Ambassador?
        </div>

        <div className="ambassador-carousal-row">

          <img src={
            currentIndex === 0 ? firstCarousal
            : currentIndex === 1 ? secondCarousal
            : currentIndex === 2 ? thirdCarousal
            : currentIndex === 3 ? fourthCarousal
            : currentIndex === 4 ? fifthCarousal
            : sixthCarousal
          } alt="" className="ambassador-carousal-img" />

          <img src={
            currentIndex === 0 ? secondCarousal
            : currentIndex === 1 ? thirdCarousal
            : currentIndex === 2 ? fourthCarousal
            : currentIndex === 3 ? fifthCarousal
            : currentIndex === 4 ? sixthCarousal
            : firstCarousal
          } alt="" className="ambassador-carousal-img" />

          <img src={
            currentIndex === 0 ? thirdCarousal
            : currentIndex === 1 ? fourthCarousal
            : currentIndex === 2 ? fifthCarousal
            : currentIndex === 3 ? sixthCarousal
            : currentIndex === 4 ? firstCarousal
            : secondCarousal
          } alt="" className="ambassador-carousal-img" />

        </div>

        <div className="mob-ambassador-carousal-row">

          <img src={
            currentIndex === 0 ? firstCarousal
            : currentIndex === 1 ? secondCarousal
            : currentIndex === 2 ? thirdCarousal
            : currentIndex === 3 ? fourthCarousal
            : currentIndex === 4 ? fifthCarousal
            : sixthCarousal
          } alt="" className="ambassador-carousal-img" />

        </div>

        <div className="ambassador-indicator-row">

          <img src={leftCheveron} alt="" className="ambassador-cheveron-img" onClick={handleLeftChevronClick} />

          {/* <div className="ambassador-indicator">
            {currentIndex === 0 && <div className="ambassador-indicator-selected"></div>}
          </div>

          <div className="ambassador-indicator">
            {currentIndex === 1 && <div className="ambassador-indicator-selected"></div>}
          </div>

          <div className="ambassador-indicator">
            {currentIndex === 2 && <div className="ambassador-indicator-selected"></div>}
          </div>

          <div className="ambassador-indicator">
            {currentIndex === 3 && <div className="ambassador-indicator-selected"></div>}
          </div>

          <div className="ambassador-indicator">
            {currentIndex === 4 && <div className="ambassador-indicator-selected"></div>}
          </div>

          <div className="ambassador-indicator">
            {currentIndex === 5 && <div className="ambassador-indicator-selected"></div>}
          </div> */}

          {[...Array(6)].map((_, index) => (
            <div key={index} className={currentIndex === index ? "ambassador-indicator-selected" : "ambassador-indicator"}></div>
          ))}

          <img src={rightCheveron} alt="" className="ambassador-cheveron-img" onClick={handleRightChevronClick} />

        </div>

      </div>



    </>
  );
};

export default AmbassadorsCarousal;