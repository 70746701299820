import React from "react";
import "../../styles/privacy/privacy.css";
import Divider from '@mui/material/Divider';

const PrivacyContent = () => {

  return (
    <>

          <div className="privacy-section">

              <div className="privacy-section-content">

                  <div className="privacy-main-heading">
                    Privacy Policy
                  </div>

                  <div className="privacy-heading-text-1">
                    Introduction
                  </div>

                  <div className="privacy-date-heading-text">
                    Effective Date: February 26, 2024
                  </div>

                  <div className="privacy-date-heading-text">
                    Welcome to Giggle!
                  </div>

                  <div className="privacy-date-regular-text-1">
                      This Privacy Policy describes how Giggle ("we" or "us") collects, uses, and shares your personal information when you use our mobile application ("App"). <br /> <br />
                      If You have any questions or comments about this Privacy Policy, please contact us at support@giggletoday.com <br /><br />
                      Your privacy is of utmost importance to Us, and hence We take it very seriously. <br /><br />
                      This Privacy Policy describes Our policies and procedures on the collection, use, processing, and disclosure of Your information, which may be inclusive of Your Personal Information when You use the Service and tells You about Your privacy rights afforded as per this policy. We use Your information to provide and improve the Service. By accessing, downloading, or using the Service, which includes mobile application, website, and/or any other extensions on any device, You agree to the collection, use, and processing of information in accordance with this Privacy Policy. This Privacy Policy must be read in conjunction with the Terms of Use, Safety Tips, Community Guidelines, and any terms disclosed and agreed to by You if You purchase additional features, products, or services We offer on the Service hereinafter collectively referred to as the “Terms”). <br /><br />
                      Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in Our Terms of Use. <br /><br />
                      Our Privacy Policy is applicable to every member, visitor, and/or invitee (referred to as “You,” “User” or “Data Subject” or “Your” as applicable) accessing, downloading and/or using the Service from any Device.
                  </div>

                  <div className="privacy-heading-text">
                    2. Information We Collect
                  </div>

                  <div className="privacy-date-regular-text">
                      2.1 Personal Information: While accessing or using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You and/or create Your Account. Personally identifiable information may include, but is not limited to: <br /> <br />
                      (a) Email address<br /> <br />
                      (b) First name and last name<br /> <br />
                      (c) Phone number<br /> <br />
                      (d) Address, State, Province, ZIP/Postal code, City<br /> <br />
                      (e) Profile Picture<br /> <br />
                      (f) Interests<br /> <br />
                      (g) Date of Birth<br /> <br />
                      (h) Gender <br /> <br />
                      While some of the information collected is mandatory for the purpose of account creation and proper authentication, other optional information may be collected at Your discretion.<br /> <br />
                      2.2 Sensitive Personal Information: While using Our Service, You may share sensitive personal information which shall not be shared with any third party unless authorized by You or be available in public domain. This may include your transactional or payment data which You use for Our Service. However, it does not include any personal data that is freely available or accessible in the public domain, furnished under the Right to Information Act, 2005, or under any other law in force.<br /> <br />
                      2.3 Non-Personal Identifiable Information or Usage Information: Usage Information is collected automatically when using the Service. It may include information but not limited to such as Your Device’s Internet Protocol (IP) address (e.g. IP address), operating system, browser type, browser version, the pages of Our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.<br /> <br />
                      We may also collect information that Your browser sends whenever You visit Our Service or when You access the Service by or through any device.<br /> <br />
                      We log Your visits and use of Our Service which includes but is not limited to viewing or clicking on Content or advertisements, performing a search, installing or updating Our mobile app, sharing content, or inviting a new User. We use log-ins, cookies, device information, and internet protocol (“IP”) addresses to identify You and log Your use.<br /> <br />
                      2.4 Location Data: We may use and store information about Your location if You give Us permission to do so. We use this data to provide features of Our Service, to improve and customize Our Service.<br /> <br />
                      You can enable or disable location Service when You use Our Service at any time by way of Your device settings. If You disable Location Services, then You may not be able to access all features of Our Service.<br /> <br />
                      2.5 Third-Party Service Provider Data: When You choose to link third-party services with Our Service, we will collect Your user ID (or equivalent) for such third-party services as well as any information that You may choose to share from that third-party service with Us.<br /> <br />
                      2.6 User Feedback: We may collect additional information at other times, including but not limited to, when You provide Feedback, modify Your content or email preferences, respond to surveys, provide comments, or communicate with Us. This information may include Your name, e-mail id, mobile number, location, etc. and only such information that You may choose to specifically provide to Us.<br /> <br />
                      2.7 Tracking Technologies and Cookies: We use Cookies and similar tracking technologies (For example, Web beacons, Pixels, Software Development Kits (SDKs) etc) to track the activity on Our Service and store certain information. Some of these Cookies are essential for Us to provide You with Our Service. We, or our third-party service providers may use cookies, mobile app analytics, and similar technologies to track visitor activity and collect data while You use/access Our Service. We may combine this data with other Personal information We have collected from User.<br /> <br />
                      “Cookies” means small pieces of information used to store on web browsers that the Website can retrieve at a later time. Cookies are used to receive and store identifiers and other information on computers, phones and other devices. Cookies include other technologies, including data that we store on Your web browser or device, identifiers associated with Your device and other software, which are used for similar purposes.<br /> <br />
                      Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on your device when You go offline, while Session Cookies are deleted as soon as You close your web browser.<br /> <br />
                      You can change cookie settings in the help section of Your browser. You should be aware that deactivation of cookies can influence the user experience of our Service.
                  </div>


                  <div className="privacy-heading-text">
                    3. Use Of Your Information
                  </div>

                  <div className="privacy-date-regular-text">
                      3.1 We may use Your Information including Personal Information for the following purposes:<br /> <br />
                      Don’t Rush<br /> <br />
                      (a) To provide and maintain Our Service;<br /> <br />
                      (b) To manage Your Account;<br /> <br />
                      (c) to notify You about changes to Our Service;<br /> <br />
                      (e) to provide customer support;<br /> <br />
                      (f) to gather analysis or valuable information so that We can improve Our Service;<br /> <br />
                      (g) to monitor the usage of Our Service;<br /> <br />
                      (h) to detect, prevent and address technical issues;<br /> <br />
                      (i) to fulfill any other purpose for which You provide it;<br /> <br />
                      (j) to carry out Our obligations and enforce Our rights arising from any contracts entered into between You and Us, including for Purchase through the Service;<br /> <br />
                      (k) to provide You with notices about your account;<br /> <br />
                      (l) to contact You by regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation;<br /> <br />
                      (m) to market and provide You with news, special offers and general information about other goods, services and events which We offer that are similar to those that You have already purchased or enquired about unless You have opted not to receive such information;<br /> <br />
                      (n) To manage Your requests;<br /> <br />
                      (p) in any other way We may describe when You provide the information;<br /> <br />
                      (q) for any other purpose with Your consent.
                  </div>

                  <div className="privacy-heading-text">
                    4. Disclosure Of Your Information
                  </div>

                  <div className="privacy-date-regular-text">
                      4.1 We may disclose Your Information, including Personal Information, in the following situations:<br /> <br />
                      (a) With Third-Party Service Providers: We may share Your information with Third-Party Service Providers to monitor and analyze the use of Our Service, for payment processing, to contact You, for meeting the obligations agreed to between You and Us.<br /> <br />
                      (b) With Third Party Service Providers: When You choose to sign up or log in using third-party authentication services, such as Google, Apple ID, or Facebook, You authorize us to collect necessary information from these services to create and maintain Your account. This may include but is not limited to your name, email address, profile picture, and other information provided by these authentication services. We use this information for account creation, authentication, and to enhance Your experience on Our Service. By signing up or logging in using third-party authentication services, You acknowledge and agree to the collection, use, processing, and disclosure of Your information as described in this Privacy Policy.<br /> <br />
                      (c) For business transfers: We may share or transfer Your information in connection with, or during negotiations of, any merger, sale of Owner assets, financing, or acquisition of all or a portion of Our business to another company.<br /> <br />
                      (d) With Affiliates, subsidiaries and successors in interest: We may share Your information with Our Affiliates, subsidiaries and successors in interest, in which case we will require them to honor this Privacy Policy.<br /> <br />
                      (e) With other Users: When You share information or otherwise interact in the public areas with other Users, such information may be viewed by all Users and may be publicly distributed outside subject to your Account settings.<br /> <br />
                      (f) With Your consent: We may disclose Your information for any other purpose with Your consent.<br /> <br />
                      (g) To gather analysis or valuable information so that We can improve Our Service;<br /> <br />
                      4.2 We may also disclose your Information, including Personal Information, in case:<br /> <br />
                      (a) Required by the law of Our Country such as to comply with a judicial order, executive orders, requirement by a law enforcement authority, or by other legal processes.<br /> <br />
                      (b) Your desired Service can only be provided if your Personal information is disclosed.<br /> <br />
                      (j) to carry out Our obligations and enforce Our rights arising from any contracts entered into between You and Us, including for Purchase through the Service;<br /> <br />
                      (c) We believe in good faith that disclosure is necessary to protect Our rights, protect Your safety or the safety of others, or investigate fraud or crime.<br /> <br />
                      4.3. We will never rent or sell Your personally identifiable information to others.<br /> <br />
                  </div>

                  <div className="privacy-heading-text">
                    5. User Rights
                  </div>

                  <div className="privacy-date-regular-text">
                      The Owner undertakes to respect the confidentiality of Your Personal Information and guarantees that You can exercise Your rights.<br /> <br />
                      You have the right under this Privacy Policy, and by law, to:<br /> <br />
                      (a) Request access, updation and deletion of Personal information We have on You directly within Your account settings section. If You are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Information We hold about You.<br /> <br />
                      (b) Request correction of any incomplete or inaccurate information We hold about You.<br /> <br />
                      (c) Object to processing of Your Personal Information: This right exists where We are relying on a legitimate interest(for example, for improving Our Service, for investigating a fraud etc) as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to Our processing of Your Personal Information on this ground. This shall be subject to the lawful processing measures.<br /> <br />
                      (d) Request deletion. You have the right to ask Us to delete or remove Personal Information.<br /> <br />
                      (e) Change Device Settings: Our Service may have permission systems for specific types of device data and notifications, such as phone contacts, pictures, location services, push notifications and advertising identifiers. You can change your settings on Your device to either consent or oppose the collection or processing of the corresponding information or the display of the corresponding notifications. If You opt to disable such settings, then You may not be able to access all features of Our Service.<br /> <br />
                      (f) Uninstall: You can stop all information collection by an app by uninstalling it using the standard uninstall process for Your device. Remember that uninstalling an app does NOT close your account. To close Your account, please use the corresponding functionality on the service.<br /> <br />
                      (g) Account closure: You can close Your account by using the corresponding functionality directly on the service.<br /> <br />
                      (h) Right to data portability: You have the right to be provided with a copy of your Personal Information in a structured, machine-readable and commonly used format.<br /> <br />
                      (i) Right to withdraw consent. You also have the right to withdraw Your consent at any time where We rely on your consent to process Your personal information.<br /> <br />
                      Please note that for protection of all Users We may ask You to verify Your identity before responding to such requests. Please note, We may not be able to provide Service without some necessary data.<br /> <br />
                      Keep in mind, we may reject requests, including if We are unable to authenticate You, if the request is unlawful or invalid, or if it may infringe on trade secrets or intellectual property or the privacy or other rights of someone else.<br /> <br />
                      Also, We may not be able to accommodate certain requests to object to or restrict the processing of personal information, notably where such requests would not allow Us to provide our service to You anymore. For instance, we cannot provide our service if we do not have your date of birth and thus cannot ensure that You are 18 years of age or older.
                  </div>

                  <div className="privacy-heading-text">
                    6. Retention Of Your Information
                  </div>

                  <div className="privacy-date-regular-text">
                      We retain Your Information, including Personal information, only as long as We need it for purposes as laid out in Section 3 and as permitted by applicable law. If You decide to stop using Our Service, You can close your account, and Your profile will stop being visible to other members. Note that We will close Your account automatically if You are inactive for a period of one year. After Your account is closed, We will delete your personal information, as laid out below:<br /> <br />
                      (a) To protect the safety and security of Our members, We implement a safety retention window of three months following Account closure or one year following an Account suspension. During this period, we keep your information in the event that it might be necessary to investigate unlawful or harmful conducts. The retention of information during this safety retention window is based on Our legitimate interest as well as that of potential third-party victims.<br /> <br />
                      (b) Once the safety retention window elapses, We delete Your data and only keep limited information for specified purposes, as laid out below:<br /> <br />
                      - We maintain limited data to comply with legal data retention obligations as required, in particular to comply with tax and accounting legal requirements, credit card information for the duration the user may challenge the transaction, and “traffic data” / logs to comply with legal data retention obligations. We also keep records of consents members give Us for five years to evidence Our compliance with applicable law.<br /> <br />
                      - We maintain limited information on the basis of our legitimate interest to support Our customer care decisions, enforce Our rights, and enable Us to defend ourselves in the event of a claim, information on the existence of past accounts and subscriptions to ensure proper and accurate financial forecasting and reporting, profile data in anticipation of potential litigation, for the establishment, exercise, or defense of legal claims, and data necessary to prevent members whose accounts were suspended or terminated for violation of Terms from opening a new account, for as long as necessary to ensure the safety and vital interests of Our members.<br /> <br />
                      - Finally, We maintain information on the basis of Our legitimate interest where there is an outstanding or potential issue, claim, or dispute requiring Us to keep information (in particular if We receive a valid legal subpoena or request asking Us to preserve data, in which case We would need to keep the data to comply with Our legal obligations) or if data would otherwise be necessary as part of legal proceedings.
                  </div>

                  <div className="privacy-heading-text">
                    7. Transfer Of Your Information
                  </div>

                  <div className="privacy-date-regular-text">
                      7.1 Your information, including Personal Information, is processed at the Owner’s operating offices in Our Country and in any other places where the parties involved in the processing are located which may be located in another Country. It means that this information may be transferred to and maintained on computers located outside of Our Country where the data protection laws may differ. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.<br /> <br />
                      7.2 The Owner will take all steps reasonably necessary to ensure that Your Information is treated securely and in accordance with this Privacy Policy, and no transfer of Your Personal Information will take place to an organization or a country unless there are adequate controls in place, including the security of Your Personal Information.<br /> <br />
                  </div>

                  <div className="privacy-heading-text">
                    8. Opting Out
                  </div>

                  <div className="privacy-date-regular-text">
                      8.1 If You wish to Opt out of Our Service, We request You to desist from accessing or using the Service in any manner.<br /> <br />
                      8.2 We reserve the right to continue to keep a copy of any of your Personal Information if required by law. We may use any aggregated/anonymized data derived from your Account, in a manner that shall not infringe upon your privacy.
                  </div>

                  <div className="privacy-heading-text">
                    9. Security Of Your Information
                  </div>

                  <div className="privacy-date-regular-text">
                    You use the Service at your own risk. We implement appropriate technical, administrative, and organizational measures to protect Information including Personal information both online and offline from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is ever fully secure or error-free. In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care in deciding what information you send to us via the Internet. Please keep this in mind when disclosing any Personal information to us via the Internet. In addition, we are not responsible for circumvention of any privacy settings or security measures while using Our Service, or third party websites. Finally, we cannot control the actions of Users on the platform, who may seek to use Third party service providers or devices to record, store, or share content or communication without other Users’ prior consent. Please keep this in mind when using the Service.
                  </div>

                  <div className="privacy-heading-text">
                    10. Children
                  </div>

                  <div className="privacy-date-regular-text">
                    Our Service is restricted to individuals who have attained Majority i.e., 18 years of age or older. We do not permit Minors i.e., individuals under the age of 16 to access or use our Service. If you suspect that a user is a minor, please report on the Support mail.
                  </div>

                  <div className="privacy-heading-text">
                    11. Personal Data Of Others
                  </div>

                  <div className="privacy-date-regular-text">
                    In some situations, you may provide Personal Information of other individuals (family, friends, Invitees likewise) to Us. If You provide Us with such Personal Information, You represent and warrant that You have obtained their consent for their Personal Information to be collected, used, processed, and disclosed as set out in this these Privacy Policy and Terms.
                  </div>

                  <div className="privacy-heading-text">
                    12. Links To Other Websites
                  </div>

                  <div className="privacy-date-regular-text">
                    Our Service may contain links to other websites that are not operated by Us. If You click on a Third-party link, You will be directed to that third party’s website and/or mobile application or similar service. We strongly advise You to review the Terms and/or Privacy Policy and/or supplementary rules/guidelines of every website/mobile application You visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or service. You further acknowledge and agree that the Owner shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or service available on or through any such websites or services.
                  </div>

                  <div className="privacy-heading-text">
                    13. Data Protection Laws Compliance
                  </div>

                  <div className="privacy-date-regular-text">
                    The Service accessed and used may be regulated by the applicable local laws and regulations on the protection of natural persons with regard to the collection, use, storage, and processing of personal data and on the free movement of such data. You may reach out to Us at the email provided in Grievance Redressal Mechanism with a subject line “Data Protection compliance”. We respond to all requests We receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws of Our Country.
                  </div>

                  <div className="privacy-heading-text">
                    14. Changes To Privacy Policy
                  </div>

                  <div className="privacy-date-regular-text">
                      14.1 We may update Our Privacy Policy from time to time. <br /><br />
                      14.2 We will notify You of any updation by posting the new Privacy Policy on this page and update the “Last updated” Notice” date at the top of this Privacy Policy.<br /><br />
                      14.3 You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                  </div>

                  <div className="privacy-heading-text">
                    15. User Support
                  </div>

                  <div className="privacy-date-regular-text">
                      Any complaints or grievances or Feedback or Suggestions or comment with regard to the breach of these Terms shall be reported by sending a mail to Support Email. <br /><br />
                      If You believe that Your work has been copied and posted on the Service in a way that constitutes copyright infringement, please submit a takedown request using the Support email support@giggletoday.com. If You contact us regarding alleged copyright infringement, please be sure to include the evidence of copyright interest along with other written submissions supporting your complaint. <br /> <br />
                      The reported issues will be acknowledged within 30 days and shall be disposed within 45 days, provided that Your report is supported with the documentary evidence. The Owner may direct you to submit additional documents to dispose of your complaint. You have the right to appeal against any action on your complaint to the Owner. The Owner shall endeavor to resolve the same in an expeditious manner. You have the right to appeal against any action on your complaint to the Owner within 15 days of the action of the Owner on the Support email provided below. The Owner shall endeavor to resolve the same in an expeditious manner, preferably within 15 days of receiving the appeal.
                  </div>


              </div>

          </div>


          <Divider sx={{ marginTop: '5%', width: '100%'}}/>

    </>
  );
};

export default PrivacyContent;