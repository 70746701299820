import React, { useState, useEffect } from "react";
import "../../styles/support_page/support_page.css";
import header from "../../assets/common/common_header.png";
import mobHeader from "../../assets/common/mob_common_header.png";
import banner from "../../assets/common/banner.png";
import join from "../../assets/support_page/help.png";
import supportAvatar from "../../assets/support_page/support_avatar.png";
import ham from "../../assets/common/ham.png";
import Drawer from '@mui/material/Drawer';
import brandBanner from "../../assets/common/brand_logo.png";
import close from "../../assets/common/close.png";
import Divider from '@mui/material/Divider';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';



const SupportHeader = () => {

  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when component mounts or route changes
    window.scrollTo(0, 0);
  }, []); 

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();

  return (
    <>

          <div className="support-section">
              <img src={header} alt="" className="support-header" />
              <img src={mobHeader} alt="" className="mob-support-header" />
              <img src={banner} alt="Banner Image" className="support-header-section-banner" onClick={() => navigate(`/`)}/>
              <img src={join} alt="Banner Image" className="support-header-section-join" />
              <img src={ham} alt="" className="support-header-section-ham" onClick={handleDrawerOpen}/>

              <div className="support-header-link-row">

                  <div className="header-link-style" onClick={() => navigate(`/support`)}>
                      Support
                  </div>

                  <div className="header-link-style" onClick={() => navigate(`/careers`)}>
                      Careers
                  </div>



                  <div className="header-link-style" onClick={() => navigate(`/ambassadors`)}>
                      Ambassadors
                  </div>

              </div>

              <Drawer
                  sx={{
                      width: '75%',
                      flexShrink: 0,
                      '& .MuiDrawer-paper': {
                          width: '75%',
                      },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={open}
              >

                  <div className="support-header-drawer-first-row">

                      <img src={brandBanner} alt="" className="header-drawer-banner" />
                      <img src={close} alt="" className="header-drawer-close" onClick={handleDrawerClose} />

                  </div>

                  <Divider sx={{ marginTop: '30%', width: '80%', marginLeft: '30px' }} />

                  <div className="header-drawer-link" onClick={() => navigate(`/support`)}>
                      Support
                  </div>

                  <div className="header-drawer-link" onClick={() => navigate(`/careers`)}>
                      Careers
                  </div>

                  <div className="header-drawer-link" onClick={() => navigate(`/ambassadors`)}>
                      Ambassadors
                  </div>




              </Drawer>

              <div className="support-avatar-main-row">

                  <div className="support-avatar-row">
                      <img src={supportAvatar} alt="" className="support-avatar-size" />

                      <div className="support-avatar-column">
                          <div className="support-avatar-email-row">

                              <div className="support-email-us">
                                  Email us at-
                              </div>

                              <div className="support-email">
                                  support@giggletoday.com
                              </div>

                          </div>

                          <div className="support-email-us">
                              Got a question or need assistance? Shoot us an email and our dedicated team will be on it in a jiffy. We're here to ensure your Giggle experience is top-notch!
                          </div>

                      </div>
                  </div>

              </div>

              <Divider sx={{width: '80%', marginTop: '50px'}}/>

              
          </div>

    </>
  );
};

export default SupportHeader;